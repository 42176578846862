import React from 'react'
import { MDXTag } from '@mdx-js/tag'



export const frontmatter = {
  title: 'About Neon',
  author: 'Chris Neale',
  draft: false,
  date: '2018-10-01T23:46:37.121Z',
  tags: ['neon', 'effects']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<MDXTag name="h3" components={components}>{`About React Neon`}</MDXTag>
<MDXTag name="p" components={components}>{`React Neon is a library for adding post-processing style effects on a canvas layer to React components. It started out as a library to put a particle effect over some buttons in Decks, and grew to be a what it is now.`}</MDXTag>
<MDXTag name="p" components={components}>{`If you like Neon please star the repo on `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/onion2k/react-neon"}}>{`Github`}</MDXTag>{`!`}</MDXTag>
<MDXTag name="p" components={components}>{`Thanks, Chris`}</MDXTag>
<MDXTag name="h4" components={components}>{`Using Neon`}</MDXTag>
<MDXTag name="p" components={components}>{`There are a few different ways to use Neon, but the simplest is by following the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/quickstart"}}>{`Quick Start`}</MDXTag>{` guide. There are examples of effects on `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://codepen.io/"}}>{`Codepen`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`If you want to talk about using Neon the Codepen chat room on `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://spectrum.chat"}}>{`Spectrum Chat`}</MDXTag>{` is a good place to start, or ask a question on Stack Overflow. If you find a problem or a bug in the code then please raise an issue in the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/onion2k/react-neon"}}>{`React Neon`}</MDXTag>{` github repo.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Contributing`}</MDXTag>
<MDXTag name="p" components={components}>{`Neon is open source and any community input would be great. How to contribute is detailed in the Contributions guide on Github.`}</MDXTag>
<MDXTag name="h4" components={components}>{`In the Wild`}</MDXTag>
<MDXTag name="p" components={components}>{`Neon can be seen in use at;`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://yt1210.ooer.com/"}}>{`Decks`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`If you're using Neon and want to be mentioned modify this page on Github and submit a PR, or add an issue.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  